<script setup lang="ts">
import { computed, ref, onUnmounted, onMounted } from "vue";
import { useRouter } from "vue-router";
import { PurchasedGift } from "../../api.generated/scion";
import { Product, Advert } from "../../components";
import {
  useBasketStore,
  useLinkDetailsStore,
  useProductStore,
} from "../../stores";
import { formatList } from "../../utils/format";
import { getAssetToken, sortPurchasedAssets } from "../../utils/purchasedAsset";
import { RouteNames } from "../../utils/routes";
import { useNavigationStore } from "../../stores/navigation";
import {
  getRemainingDuration,
  getDaysDuration,
  isExpired,
  formatAssetValue,
} from "../../utils/stores/utils";
import { AssetsSuccessfullyCreatedModal } from "../../components";
import { event as gaEvent } from "vue-gtag";

const router = useRouter();
const linkDetailsStore = useLinkDetailsStore();
const productStore = useProductStore();
const basketStore = useBasketStore();
const navigationStore = useNavigationStore();
const numberOfAssets = Number(navigationStore.numberOfAssets);

function handleAssetsSuccessfullyCreatedModal() {
  navigationStore.setPurchaseSuccess(false);
  navigationStore.setNumberOfAssets(0);
}

const activePurchasedGifts = computed(() => {
  return linkDetailsStore.purchasedAssets
    .sort(sortPurchasedAssets)
    .filter((asset) => !isExpired(asset.expiry));
});

const expiredPurchasedGifts = computed(() => {
  return linkDetailsStore.purchasedAssets
    .sort(sortPurchasedAssets)
    .filter((asset) => isExpired(asset.expiry));
});

function getCategoryNames(categories: string[]): string[] {
  return categories
    .map((code) => productStore.categories.find((c) => c.code === code)?.name)
    .filter((c) => !!c) as string[];
}

onMounted(() => {
  gaEvent("page_view", {
    page_title: "Assets",
    hostname: window.location.hostname,
  });
});

onUnmounted(() => {
  // There is scenario that user click back button on browser, we want to clear reset values
  navigationStore.setPurchaseSuccess(false);
  navigationStore.setNumberOfAssets(0);
});

function onPurchasedGiftSelected(asset: PurchasedGift) {
  const assetToken = getAssetToken(asset.redeemUrl as string);

  router.push({
    name: RouteNames.Spend,
    params: {
      assetToken,
    },
  });
}

const purchasingErrorMessage = ref<string>(
  basketStore.getPurchasingErrorMessage
);
</script>

<template>
  <div
    class="customisations h-full"
    :style="{ '--bg-color': linkDetailsStore.customisation.backgroundColour }"
  >
    <div class="m-auto max-w-3xl md:p-6">
      <div class="rounded-2xl bg-white p-6">
        <div class="mb-6 flex items-center">
          <button @click="router.back()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-8 w-8 rounded-full bg-grey-300 p-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>
          <h1 class="font-f37lineca flex-1 text-center text-xl">Your assets</h1>
          <div class="w-8"></div>
        </div>

        <div
          v-if="!!purchasingErrorMessage"
          class="h-a uto my-4 rounded-xl bg-error-200 px-4 py-3 text-sm"
        >
          <div>
            {{ purchasingErrorMessage }}
          </div>
        </div>

        <div>
          <div
            v-for="asset in activePurchasedGifts"
            :key="asset.id"
            class="mb-4 rounded-2xl bg-grey-300 p-3 last:mb-0"
            @click="onPurchasedGiftSelected(asset)"
          >
            <button class="w-full">
              <div class="mb-3 flex items-start justify-between">
                <Product
                  class="mr-3 w-[96px]"
                  :product="asset.product"
                ></Product>

                <div class="mr-2 flex-1 text-left">
                  <h2 class="font-semibold">
                    {{ asset.product.name }}
                  </h2>
                  <span>
                    {{ formatList(getCategoryNames(asset.product.categories)) }}
                  </span>
                </div>

                <span class="font-semibold">
                  {{ formatAssetValue(asset) }}
                </span>
              </div>

              <div
                :class="[
                  'flex items-center',
                  getDaysDuration(asset.expiry) <= 7 ? 'text-orange' : '',
                ]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="mr-2 h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span>
                  Expires in {{ getRemainingDuration(asset.expiry) }}
                </span>
              </div>
            </button>
          </div>

          <h3 v-if="expiredPurchasedGifts.length" class="mb-4 font-semibold">
            Expired assets
          </h3>

          <div
            v-for="asset in expiredPurchasedGifts"
            :key="asset.id"
            class="mb-3 rounded-2xl bg-grey-300 p-4 last:mb-0"
          >
            <div class="mb-3 flex items-start justify-between">
              <Product
                class="pointer-events-none mr-3 w-[96px] opacity-50 grayscale"
                :product="asset.product"
              ></Product>

              <div class="mr-2 flex-1 text-left">
                <h2 class="font-semibold">
                  {{ asset.product.name }}
                </h2>
                <span>
                  {{ formatList(getCategoryNames(asset.product.categories)) }}
                </span>
              </div>

              <span class="font-semibold">
                {{ formatAssetValue(asset) }}
              </span>
            </div>

            <div class="flex items-center text-red">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mr-2 h-6 w-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <span> Expired </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="linkDetailsStore.hasBannerAdvert" class="mt-6">
        <advert
          :image="linkDetailsStore.advert.bannerImageUrl"
          :target="linkDetailsStore.advert.bannerLink"
        />
      </div>
    </div>
    <AssetsSuccessfullyCreatedModal
      :numberOfAssets="numberOfAssets"
      @on-asset-succssfully-created-modal-closed="
        handleAssetsSuccessfullyCreatedModal
      "
      v-show="navigationStore.purchaseSuccess"
    />
  </div>
</template>

<style>
.customisations {
  background-color: var(--bg-color);
}
</style>
